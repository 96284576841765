//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\

/*
    1) CUSTOM SCRIPTS
    2) CALCULATOR CODE
    3) FIND A REP CODE
*/

//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_--_-_-_-_-_-_-_-_-_||| 1) CUSTOM SCRIPTS  |||-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\



$(document).ready(function(){

    $(window).stellar();
    //Product page fade in product name navigation
    $(document).scroll(function(){
        var y = $(this).scrollTop();
        if (y > 565) {
            $('.black-nav-product').fadeIn(200);
        } else {
            $('.black-nav-product').fadeOut(200);
        }
    });

    $(document).ready(function(){
        $('.dropdown-toggle').dropdown();
    });

    //Product family page fade in black bar.
    $(document).scroll(function(){
        var y = $(this).scrollTop();
        if (y > 200) {
            $('.black-nav-product-family').fadeIn(200);
        } else {
            $('.black-nav-product-family').fadeOut(200);
        }
    });

    //Product family page fade in black bar.
    $(document).scroll(function(){
        var y = $(this).scrollTop();
        if (y > 110) {
            $('.black-nav-general').fadeIn(200);
        } else {
            $('.black-nav-general').fadeOut(200);
        }
    });

    //Product family page fade in black bar.
    $(document).scroll(function(){
        var y = $(this).scrollTop();
        if (y > 5) {
            $('.black-nav-home').fadeIn(200);
        } else {
            $('.black-nav-home').fadeOut(200);
        }
    });

    $(document).scroll(function(){
        //stick nav to top of page
        var y = $(this).scrollTop();
        var nav = $('#navWrap');
        if (nav.length){
            var navWrap = nav.offset().top;
            if (y + 75 > navWrap) {
                $('nav').addClass('sticky');
            } else {
                $('nav').removeClass('sticky');
            }
        }
    });

    $(document).scroll(function(){
        //stick nav to top of page
        var y = $(this).scrollTop();
        var nav = $('#navWrap');
        if (nav.length){
            var navWrap = nav.offset().top;
            if (y + 100 > navWrap) {
                $('nav.rep').addClass('sticky-rep');
            } else {
                $('nav.rep').removeClass('sticky-rep');
            }
        }
      });


    $(document).ready(function() {

		$('.nav-tabs > li > div').click(function(event){
		event.preventDefault();//stop browser to take action for clicked anchor

		//get displaying tab content jQuery selector
		var active_tab_selector = $('.nav-tabs > li.active > div').attr('href');

		//find actived navigation and remove 'active' css
		var actived_nav = $('.nav-tabs > li.active');
		actived_nav.removeClass('active');

		//add 'active' css into clicked navigation
		$(this).parents('li').addClass('active');

		//hide displaying tab content
		$(active_tab_selector).removeClass('active');
		$(active_tab_selector).addClass('hide');

		//show target tab content
		var target_tab_selector = $(this).attr('href');
		$(target_tab_selector).removeClass('hide');
		$(target_tab_selector).addClass('active');
	     });
	  });

    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
        });
    });

    $(function() {
        $('#na-map-hide').click(function() {
            $("#map-svg-container").fadeToggle(250, function(){
                $("#international-dropdown-container").fadeToggle(250);
            });
            $('#int-map-hide').toggleClass('hide');
            $('#na-map-hide').addClass('hide');
            //$('.map-svg-container').toggleClass('hide');
            //$('.international-dropdown-container').toggleClass('hide');
        });
    });

    $(function() {
        $('#int-map-hide').click(function() {
            $("#map-svg-container").fadeToggle(250, function(){
                $("#international-dropdown-container").fadeToggle(250);
            });
            $('#na-map-hide').toggleClass('hide');
            $('#int-map-hide').addClass('hide');
            //$('.map-svg-container').toggleClass('hide');
            //$('.international-dropdown-container').toggleClass('hide');
        });
    });
    //left and right scrolling on product gallery

    $(document).ready(function(){
    var item_width = 700;
    $("#left").on("click",function(){
        if(parseInt($(".wrap").css("margin-left")) == 0) return;
         $(".wrap").animate({marginLeft: "+="+item_width}, 1000);
        return false;
    });
    $("#right").on("click",function(){
        $(".wrap").animate({marginLeft: "-="+item_width}, 1000);
        return false;
    });

	// Enable the Swiper slider
    var swiper = new Swiper('.swiper', {
		slidesPerView: 2,
		centeredSlides: true,
		spaceBetween: 8,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
    });
});
   /* (function($){
        $(window).load(function(){
            $("#general-thumbs-list").mThumbnailScroller({
              axis:"y" //change to "y" for vertical scroller
            });
        });
    })(jQuery);*/

    var thumbnailLinks = $('img.thumbnailLink') // Add class="thumbnailLink" where appropriate or use a different selector.
    $('.thumbnailLink').click(function() {
        $('.bigimg').attr('src', $(this).attr('src'));
    });

    //Menu Scripts

    $( ".menu-container" ).hide();
    $( ".menu-open" ).click(function() {
        $('body').toggleClass('fixPosition');
        $(this).toggleClass('open');
        $( ".menu-container" ).slideToggle( "slow", function() {
        });
    });

    $( ".secondary-nav-home .steam-team-logo" ).click(function() {
		$( ".menu-open" ).trigger( "click" );
    });

    $(function() {
        $("#add-click").click(function() {
            $("#zero, #first, #second, #third, #fourth").toggleClass("placement");
        });
    });


    $(function() {
        $("#add-click").click(function() {
            $("#first-column, #second-column, #third-column, #fourth-column, #fifth-column").toggleClass("menu-col-pos");
        });
    });

	$(function() {
        $("#add-click").click(function() {
            $('#translateElements').toggle();
        });
    });


    /*$(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $("header").addClass("active-bg");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
            $("header").removeClass("active-bg");
        }
    });*/
    $(function () {
        $(".category-column").click(function() {
             $('.category-square').removeClass('category-active');
             $(this).children('.category-square').addClass('category-active');
        });
    });

    $('.show-app').click(function() {
        //$('#appSection').toggle();
		document.getElementById("appSection").style.display = "block";
        $('.cta-button').addClass('noLongerShow');
        $('#hideOnClick').addClass('noLongerShow');
    });

    function close_accordion_section() {
        $('.accordion .accordion-section-title').removeClass('active');
        $('.accordion .accordion-section-content').slideUp(300).removeClass('open');
    }

    $('.accordion-section-title').click(function(e) {
        // Grab current anchor value
        var currentAttrValue = $(this).attr('href');

        if($(e.target).is('.active')) {
            close_accordion_section();
        }else {
            close_accordion_section();

            // Add active class to section title
            $(this).addClass('active');
            // Open up the hidden content panel
            $('.accordion ' + currentAttrValue).slideDown(300).addClass('open');
        }

        e.preventDefault();
    });

    $("#accordian .menu-item-has-children > a").click(function(){
		//slide up all the link lists
		$("#accordian ul ul").slideUp();
		//slide down the link list below the h3 clicked - only if its closed
		if(!$(this).next().is(":visible"))
		{
			$(this).next().slideDown();
		}
	});


    $(".state").click(function(){
       $(".state").removeClass('active');
       $(this).addClass('active');
    });

    $.featherlightGallery.prototype.afterContent = function() {
        var caption = this.$currentTarget.find('img').attr('alt');
        this.$instance.find('.caption').remove();
        $('<div class="caption" style="color: #fff; font-size: 16px; text-align: left;">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
};
});



function dropTog() {
    document.getElementById("main-dropdown").classList.toggle("show");
}

// Close the dropdown menu if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.matches('.international-dropdown')) {

    var dropdowns = document.getElementsByClassName("dropdown-menu");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}



//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_--_-_-_-_-_-_-_-_-_||| 2) CALCULATOR CODE |||-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\




	var x = 5;
	var y = 4;
	var total=x+y;


	var OpenWindow = "ProcessInformation";
	var jQBoilerSize = 0;
	var jQBoilerEfficiency = 0;
	var jQFuel = 0;
	var jQOperatingHours = 0;

	/* Current APPROXIMATE Fuel Price Here   */

	var jQFuelCost = 0;
        var jQGasCost = 3.16;
        var jQOilCost = 4.05;

	/* Current APPROXIMATE Fuel Price Here  */

	var jQExcessAirAdder = 0;
	var jQFGRAdder = 0;
	var jQMwater = 0;
	var jQMgas = 0;
	var jQTgasin = 0;
	var jQTgasout = 0;
	var jQHeatRecovered = 0;
	var jQHeatRecovered_yr_mmBtu = 0;
	var jQHeatRecovered_yr_therm = 0;
	var jQMetricTonsCO2 = 0;

	var jQFuelSaved_yr = 0;


	$(document).ready(function(){

                //alert("hello World");
                $('#Input2').hide();
		$('#Input3').hide();
                $('#txtFuelCost').attr({value:jQGasCost})

/* controls for the alternate unit links  */
		$('#altBoilerHP').click(function(event){
			if ($('#altBoilerHP').text() == "Change Units to BHP") {

				$('#altBoilerHP').text("Change Units to PPH");
				$('#lblBoilerSize').text("Boiler Size (BHP)");
			}
			else {
				$('#altBoilerHP').text("Change Units to BHP");
				$('#lblBoilerSize').text("Boiler Size (PPH)");


			};


		});
/* controls for fuel prices */
		$('#drpFuel').click(function(event){
			if ($('#drpFuel').val() == "Natural Gas") {

				$('#lblFuelCost').text("Fuel Cost   ($/mmBtu)");
                                $('#txtFuelCost').attr({value:jQGasCost});

			}
			else {
				$('#lblFuelCost').text("Fuel Cost   ($/Gal)");
				$('#txtFuelCost').attr({value:jQOilCost});


			};


		});
/* controls for the continue buttons */
		$('#btnContinue1').click(function(event){

			SeeApplicationDetails();

		});

		$('#btnContinue2').click(function(event){
			SeeSavings();
                        goToByScroll("Input1");
		});

		/*This is where the controls for the header ribbon buttons go------------------------------------------------------------*/

		$('#Header2').click(function(event){

			SeeApplicationDetails();
		});

		$('#Header3').click(function(event){
                        SeeSavings();
                        goToByScroll("Input1");



		});

		$('#Header1').click(function(event){
			SeeProcessInformation()

		});


/* CONTROL functions */
		function SeeProcessInformation()
			{

			if( OpenWindow == "ProcessInformation") {


			}
			else if (OpenWindow == "ApplicationDetails") {
                                if (ValidateBoilerSize("FuelCost","ApplicationDetails","Input Valid Number") == false || ValidateBoilerSize("OpHours","ApplicationDetails","Input Valid Number") == false  )
					{
						OpenWindow = "ApplicationDetails";
					}
					else{

                                                $('#Input2').slideUp('slow', 'linear');
				                $('#btnContinue2').slideUp('fast');
			                        $('#Input1').slideDown('slow');
			                        $('#btnContinue1').slideDown('fast');
				                OpenWindow = "ProcessInformation"

                                        }

			}
			else if (OpenWindow == "Savings") {
				$('#Input3').slideUp('slow', 'linear');
			        $('#Input1').slideDown('slow');
			        $('#btnContinue1').slideDown('fast');
                                OpenWindow = "ProcessInformation"
			};



		};


		function SeeApplicationDetails()
			{


			if( OpenWindow == "ProcessInformation") {



				if (ValidateBoilerSize("BoilerSize","ProcessInformation","Input Valid Number") == false || ValidateBoilerSize("BoilerFlueGasOut","ProcessInformation","Input Valid Number") == false )
					{
						OpenWindow = "ProcessInformation";

					}
					else{
						$('#Input1').slideUp('slow', 'linear');
						$('#btnContinue1').slideUp('fast');
						$('#Input2').slideDown('slow');
						$('#btnContinue2').slideDown('fast');
						OpenWindow = "ApplicationDetails";
					};


			}
			else if (OpenWindow == "ApplicationDetails") {

			}
			else if (OpenWindow == "Savings") {
				$('#Input3').slideUp('slow', 'linear');
				$('#Input2').slideDown('slow');
				$('#btnContinue2').slideDown('fast');
				OpenWindow = "ApplicationDetails";
			};

                 };

		function SeeSavings()
			{
			if( OpenWindow == "ProcessInformation") {


				if (ValidateBoilerSize("BoilerSize","ProcessInformation","Input Valid Number") == false || ValidateBoilerSize("BoilerFlueGasOut","ProcessInformation","Input Valid Number") == false  )
					{
						OpenWindow = "ProcessInformation";
					}
					else{
						$('#Input1').slideUp('slow', 'linear');
						$('#btnContinue1').slideUp('fast');
						$('#Input3').slideDown('slow');
						OpenWindow = "Savings";
					};


			}
			else if (OpenWindow == "ApplicationDetails") {
				if (ValidateBoilerSize("FuelCost","ApplicationDetails","Input Valid Number") == false || ValidateBoilerSize("OpHours","ApplicationDetails","Input Valid Number") == false  )
					{
						OpenWindow = "ApplicationDetails";
					}
					else{

                                                $('#Input2').slideUp('slow', 'linear');
				                $('#btnContinue2').slideUp('fast');
				                $('#Input3').slideDown('slow');
				                OpenWindow = "Savings";

                                        }
			}
			else if (OpenWindow == "Savings") {

			};

			SavingsMath();

			/*This goes at the bottom to allow for checks*/




		};

/* Supplemental Functions */

               function ValidateBoilerSize(FieldName,WindowName,Text)
			{

			var TempNum = 0;
			FieldName += "";

			if ($('#txt' + FieldName).val()==null || $('#txt' + FieldName).val()=="" || $('#txt' + FieldName).val()=="0"|| $('#txt' + FieldName).val()== Text)
				{

					$('#lbl'+ FieldName).attr({style:'color:red'});

					$('#txt'+ FieldName).attr({value:Text});


					return false;
				}
			else {
					/*this cleans out any special characters besides decimals    */
					TempNum = $('#txt'+ FieldName).val();
					TempNum = TempNum.replace(/[^0-9.]/g,"");
					$('#txt'+ FieldName).attr({value:TempNum});


					if (FieldName == "BoilerSize")
						{
						if ($('#lblBoilerSize').text() == "Boiler Size (BHP)")
							 {
							if($('#txtBoilerSize').val() > 2500) {
								$('#txtBoilerSize').attr({value:"Change Units to PPH"});
								alert("This number seems abnormally large; Please input your Boiler Size in PPH");
								$('#lblBoilerSize').attr({style:'color:red'});
								return false;
								}
							else {
								$('#lblBoilerSize').attr({style:'color:black'});
								return true;

								};
							}
						else {
							if($('#txtBoilerSize').val() > 2500000) {
								$('#txtBoilerSize').attr({value:"Input Valid Number"});
								alert("This number seems abnormally large; Please double check your figures.");	 						                $('#lblBoilerSize').attr({style:'color:red'});
								return false;
								}
							else {
								$('#lblBoilerSize').attr({style:'color:black'});
								return true;

								};
							};
						}
                                       else if (FieldName == "FuelCost")
						{
							if($('#txtFuelCost').val() > 100) {
								$('#txtFuelCost').attr({value:"Fuel Cost is High"});
								$('#lblFuelCost').attr({style:'color:red'});
								return false;
								}
							else {
								$('#lblFuelCost').attr({style:'color:black'});
								return true;

								};
						}
                                       else if (FieldName == "BoilerFlueGasOut")
						{
							if($('#txtBoilerFlueGasOut').val() > 1500) {
								$('#txtBoilerFlueGasOut').attr({value:"Temp is High"});
								$('#lblBoilerFlueGasOut').attr({style:'color:red'});
								return false;
								}
							else {
								$('#lblBoilerFlueGasOut').attr({style:'color:black'});
								return true;

								};
						}
					else if (FieldName == "OpHours")
						{
							if($('#txtOpHours').val() > 8766) {
								$('#txtOpHours').attr({value:"Enter in HRS/YR"});
								$('#lblOpHours').attr({style:'color:red'});
								return false;
								}
							else {
								$('#lblOpHours').attr({style:'color:black'});
								return true;

								};
						}


					$('#lbl'+ FieldName).attr({style:'color:black'});
					return true;
				};


			};






/* SHOW ME THE MATHS BABY */
	function SavingsMath()
		{





			jQBoilerSize = $('#txtBoilerSize').val();
			jQTgasin = $('#txtBoilerFlueGasOut').val();
			jQFuel = $('#drpFuel').val();


			jQOperatingHours = $('#txtOpHours').val();
			jQFuelCost = $('#txtFuelCost').val();

			if (jQFuel == "Natural Gas") {
				jQExcessAirAdder = 1.28;
				jQTgasout = 260;
			}
			else {
				jQExcessAirAdder = 1.25
				jQTgasout = 300;
			};

			if ($('#lblBoilerSize').text() == "Boiler Size (BHP)") {
				jQMwater = jQBoilerSize*34.5*1.02;
				jQMgas = jQMwaterjQMgas = jQMwater*jQExcessAirAdder;
			}
			else {
				jQMwater = jQBoilerSize*1.02
				jQMgas = jQMwater*jQExcessAirAdder;
			};


			jQHeatRecovered = jQMgas*0.275*(jQTgasin-jQTgasout);

			jQHeatRecovered_yr_mmBtu = jQOperatingHours*jQHeatRecovered/1000000;

			jQFuelSaved_yr = jQHeatRecovered_yr_mmBtu*jQFuelCost;

			if (jQFuel == "Natural Gas") {
			     jQHeatRecovered_yr_therm = jQHeatRecovered_yr_mmBtu*10;
                             jQMetricTonsCO2 = 0.005*jQHeatRecovered_yr_therm;
                        }
                        else {
			     /*Obtained this from---- http://www.eia.gov/tools/faqs/faq.cfm?id=307&t=11     ---*/
                             jQMetricTonsCO2 = 0.010151*(jQHeatRecovered_yr_mmBtu/0.14);

			};

			$('#Result').text("$" + addCommas(Math.floor(jQFuelSaved_yr)) );
			$('#C02Result').html("Cuts down on <b style='color: #014C90'>" + addCommas(Math.floor(jQMetricTonsCO2))+"</b> metric tons of CO2 emmissions annually "  );
			$('#GasResult').html("Which is the same as CO2 emissions from consuming <b style='color: #014C90'>" + addCommas(Math.floor(jQMetricTonsCO2/.00892))+ "</b> gallons of gasoline"  );
			$('#TreesResult').html("Same amount of C02 annually removed from the atmosphere by <b style='color: #014C90'>" + addCommas(Math.floor(jQMetricTonsCO2/4.69))+"</b> acres of pine forest"  );


		};

			function goToByScroll(id)
			{

			       /*Obtained this from---- $('html,body').animate({scrollTop: $("#"+id).offset().top-600},'slow');  ---*/

                               $("html").animate({scrollTop:300},'slow');
			 };



}); //end of ready.application



/* Supplemental Functions */

		function addCommas(nStr)
			{


				nStr += "";
				var x = nStr.split(".");
				var x1 = x[0];
				var x2;

				if (x.length > 1){
					x2 = "." + x[1];
				}
				else {
					x2 = "";
				};
				var test = null;
				var i = 0;

				var y = x1.split("");

				var CommaNum = y.length/3;
				var CommaLoc = 0;

				CommaNum += "";
				var CommaNum1 = CommaNum.split(".");



				if (CommaNum1[0] >= 1)
					{

						x1 = y[0];
						for (i = 1 ; i <= y.length - 1; i++)
							{

							CommaLoc = (y.length - i)/3;

							CommaLoc += "";

							CommaLoc = CommaLoc.split(".");

							if (CommaLoc.length > 1)
								{
								x1 = x1  + y[i];

								}
							else
								{
								x1 = x1 + "," + y[i];
								};


							};

					}



				return x1 + x2;
			};



//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_--_-_-_-_-_-_-_-_-_||| 3) FIND A REP CODE |||-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\
//_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_\\

var xmlHttp;
var changeTop;

// Check Login------------------------------
function showRep2()
{
	//alert(which);
	var curFilter = "";
	var locationSelector = document.getElementById("locationSelector").value;

	infoBox = infoBox2 = "repList2";

	if(document.getElementById('Watertube').checked)
	{
		curFilter += 'Watertube';
	}

	if(document.getElementById('Firetube').checked)
	{
		curFilter += 'Firetube';
	}

	if(document.getElementById('HRSG').checked)
	{
		curFilter += 'HRSG';
	}

	if(document.getElementById('Burners').checked)
	{
		curFilter += 'Burners';
	}

	if (infoBox.length >= 0)
	{
		var url="/wp-content/themes/theme-victory/views/ajax-find-a-rep.php?location=" + locationSelector + '&filters=' + curFilter;
		xmlHttp=GetXmlHttpObject(stateChanged);
	  	xmlHttp.open("GET", url , true);
		xmlHttp.send(null);
   	}
   	else
	{
   		document.getElementById(infoBox).innerHTML="";
	}
	document.getElementById("repList").style.display = "block";
}




//------------------------------
function stateChanged()
{
	if (xmlHttp.readyState==4 || xmlHttp.readyState=="complete")
	{
	   	document.getElementById(infoBox2).innerHTML=xmlHttp.responseText;
   	}
}

function GetXmlHttpObject(handler)
{
var objXmlHttp=null
if (navigator.userAgent.indexOf("Opera")>=0)
   {
    alert("This example doesn't work in Opera");
    return;
   }
if (navigator.userAgent.indexOf("MSIE")>=0)
   {
   var strName="Msxml2.XMLHTTP";
   if (navigator.appVersion.indexOf("MSIE 5.5")>=0)
      {
      strName="Microsoft.XMLHTTP";
      }
   try
      {
      objXmlHttp=new ActiveXObject(strName);
      objXmlHttp.onreadystatechange=handler;
      return objXmlHttp;
      }
   catch(e)
      {
      alert("Error. Scripting for ActiveX might be disabled");
      return
      }
    }
if (navigator.userAgent.indexOf("Mozilla")>=0)
   {
   objXmlHttp=new XMLHttpRequest();
   objXmlHttp.onload=handler;
   objXmlHttp.onerror=handler;
   return objXmlHttp;
   }
}